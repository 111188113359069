<template>
  <BaseModal ref="modal" title="Akte" max-width="tw-max-w-4xl" @hide="onHide">
    <div v-if="values.has_suspensive_condition">
      <p class="tw-text-danger tw-font-bold">
        Er is een opschortende voorwaarde van toepassing voor dit dossier.
        Gelieve deze eerst af te werken alvorens de akte te vervolledigen.
      </p>
    </div>
    <FormulateForm
      v-else-if="!formSaved"
      #default="{ isLoading }"
      v-model="values"
      name="deedForm"
      @submit="submit"
    >
      <h2 class="tw-mt-0">Akte-informatie</h2>
      <FormulateInput
        label="Vooropgestelde aktedatum"
        type="date"
        name="deed_date"
        class="tw-mt-0 tw-w-full md:tw-w-1/3"
      />
      <FormulateInput
        label="Akte is doorgegaan"
        type="checkbox"
        name="is_deed_signed"
        class="tw-mt-0"
      />
      <div
        v-if="deedMessageType"
        class="tw-w-full tw-mb-4 tw-p-4 tw-rounded-md tw-shadow-card"
      >
        <p
          v-if="deedMessageType === 'upcoming_deed'"
          class="tw-font-bold"
        >
          Akte gaat door over {{ daysBeforeDeed }} {{ daysBeforeDeed === 1 ? 'dag' : 'dagen' }}
        </p>
        <p v-if="deedMessageType === 'today_deed'">Akte gaat vandaag door</p>
        <p v-if="deedMessageType === 'past_deed'">Aktedatum ligt in het verleden. Gelieve dit te bevestiging via dit formulier.</p>
        <div>
            <a
              v-for="(invoice, index) in finalBills"
              :key="invoice.id"
              :href="invoice.property_file.url"
              title="Factuur downloaden"
              class="tw-m-1 btn btn-xs btn-primary"
            >
              <i class="far fa-download tw-ml-1" />
              Eindfactuur <span>{{ finalBills.length > 1 ? index + 1 : '' }}</span>
            </a>
            <router-link
              v-if="!(finalBills && finalBills.length)"
              :to="{ name: 'PropertyInvoice', params: { id: propertyId } }"
              target="_blank"
              class="btn btn-xs btn-primary"
            >
              Eindfactuur opmaken
            </router-link>
        </div>
      </div>
      <hr />
      <h2>Verkopers</h2>
      <EntityContactsFormPartial
        ref="entityOwners"
        contact-role="owner"
        :form-values="values"
        :property-id="propertyId"
        :show-disclaimer="false"
      />
      <FormulateInput
        type="autocomplete"
        auto-complete-type="contact"
        name="notary_of_owners"
        label="Notaris verkopers"
        placeholder="Zoek op contact"
        :outer-class="['tw-w-full md:tw-w-1/2 md:tw-pr-4']"
      />
      <hr />
      <h2>Kopers</h2>
      <EntityContactsFormPartial
        ref="entityBuyers"
        contact-role="buyer"
        :form-values="values"
        :property-id="propertyId"
        :show-disclaimer="false"
      />
      <FormulateInput
        type="autocomplete"
        auto-complete-type="contact"
        label="Notaris kopers"
        name="notary_of_buyers"
        placeholder="Zoek op contact"
        :outer-class="['tw-w-full md:tw-w-1/2 md:tw-pr-4']"
      />
      <hr />
      <h2>Publicatie op Dewaele.com</h2>
      <FormulateInput
        type="checkbox"
        name="publish_as_dewaele_reference"
        label="Behouden als referentie op Dewaele.com"
        outer-class="tw-m-0"
      />
      <div class="tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end">
        <FormulateErrors />
        <FormulateInput
          v-show="isSuperUser"
          type="button"
          :input-class="['tw-bg-gray-500']"
          outer-class="tw-my-2"
          @click="cancelDossier"
        >
          <i
            :class="[
              'fas tw-mr-2 fa-arrow-left'
            ]"
          />
          Verkoop annuleren
        </FormulateInput>
        <FormulateInput
          type="submit"
          title="Opslaan"
          :disabled="isLoading"
          outer-class="tw-my-2"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-badge-check'
            ]"
          />
          Opslaan
        </FormulateInput>
      </div>
    </FormulateForm>
    <div v-else-if="formSaved">
      <h2 class="tw-mt-0">Openstaande leads</h2>
      <WizardLeadCount :property-id="propertyId" />
      <h2 class="tw-mt-8">Openstaande checklistitems</h2>
      <WizardChecklistItems :property-id="propertyId" />
    </div>
    <PropertyCancelDossierModal
      ref="propertyCancelDossierModal"
      :property-id="propertyId"
      @dossier-cancelled="dossierCancelled"
    />
  </BaseModal>
</template>

<script>

import EntityContactsFormPartial from '@/components/properties/EntityContactsFormPartial'
import PropertyCancelDossierModal from '@/components/properties/PropertyCancelDossierModal'
import WizardLeadCount from '@/components/properties/WizardLeadCount'
import WizardChecklistItems from '@/components/properties/WizardChecklistItems'
import EventBus from '@/components/iam/bus'
import {
  getPropertyBuyers,
  getPropertyDeedData,
  postPropertyDeed,
  updateProperty
} from '@/services/properties'
import { daysBetweenDates } from '@/utils/helpers'
import { mapState } from 'vuex'

export default {
  name: 'WizardDeed',
  components: {
    EntityContactsFormPartial,
    PropertyCancelDossierModal,
    WizardLeadCount,
    WizardChecklistItems
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      formSaved: false,
      checks: [],
      values: {},
      property: null,
      checklist: null,
      finalBills: []
    }
  },
  computed: {
    ...mapState('account', ['user']),
    isSuperUser () {
      return this.user.is_superuser
    },
    daysBeforeDeed () {
      const inputDate = this.values?.deed_date
      if (!inputDate) return
      const now = new Date()
      now.setHours(0)
      now.setMinutes(0)
      now.setSeconds(0)
      now.setMilliseconds(0)
      return daysBetweenDates(new Date(inputDate), now)
    },
    deedMessageType () {
      if (this.daysBeforeDeed === 0) {
        return 'today_deed'
      }
      if (this.daysBeforeDeed > 0 && this.daysBeforeDeed <= this.values?.deed_with_x_days_display_message_threshold) {
        return 'upcoming_deed'
      }
      if (this.daysBeforeDeed < 0) {
        return 'past_deed'
      }
      return ''
    }
  },
  methods: {
    daysBetweenDates,
    hide () {
      this.$refs.modal.hide()
      this.values = {}
    },
    onHide () {
      if (this.formSaved) EventBus.$emit('initProperty')
    },
    dossierCancelled () {
      this.$emit('dossierCancelled')
      this.hide()
    },
    cancelDossier () {
      this.$refs.propertyCancelDossierModal.show()
    },
    async show () {
      const deedData = await this.loadDeedData()
      await Promise.all([this.fetchPropertyBuyers(), this.fetchPropertyChecklist()])
      this.$refs.modal.show()
      return deedData
    },
    async loadDeedData () {
      const response = await getPropertyDeedData(this.propertyId)
      this.values = response.data
      this.finalBills = response.data?.final_bills
      return response
    },
    async fetchPropertyBuyers () {
      const response = await getPropertyBuyers(this.propertyId)
      const buyers = response.data
      this.$set(this.values, 'buyers', buyers)
    },
    async submit (data) {
      try {
        const owners = await this.$refs.entityOwners.submit()
        const buyers = await this.$refs.entityBuyers.submit()
        const propertyPayload = {
          deed_date: data.deed_date,
          is_deed_signed: data.is_deed_signed,
          status: 10,
          notary_of_buyers: data.notary_of_buyers?.id,
          notary_of_owners: data.notary_of_owners?.id
        }
        const propertyResponse = await updateProperty(this.propertyId, propertyPayload)
        const deedPayload = { publish_as_dewaele_reference: data.publish_as_dewaele_reference }
        const deedResponse = await postPropertyDeed(this.propertyId, deedPayload)
        this.formSaved = true
        return [owners, buyers, propertyResponse, deedResponse]
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'deedForm')
      }
    }
  }
}
</script>
